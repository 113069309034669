import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Hero from "../../components/hero";
import HorizontalTestimonial from "../../components/horizontal-testimonial";
import { StaticImage } from "gatsby-plugin-image";
import HeroEnd from "../../components/hero-end";

const WebDesignersAndDigitalMarketers = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				heroImg: file(relativePath: { eq: "16.jpg" }) {
					publicURL
					childImageSharp {
						fluid(quality: 100, maxWidth: 1920) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				MikeS: file(relativePath: { eq: "testimonials/Mike-Sherwood.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				daniel: file(
					relativePath: { eq: "testimonials/daniel-brookbank.jpg" }
				) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				robbie: file(relativePath: { eq: "testimonials/robbie-mould.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				andrew: file(relativePath: { eq: "testimonials/selda-koydemir.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
			}
		`
	);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const MikeS = data.MikeS.childImageSharp.gatsbyImageData;
	const robbie = data.robbie.childImageSharp.gatsbyImageData;
	const schemaImage = data.heroImg.publicURL;
	const title =
		"Web Designers & Digital Marketers | Business & Executive Coaching in London";
	const description =
		"Web designers and digital marketers, experts in crafting compelling online experiences, often encounter hurdles when trying to grow or scale their businesses profitably. As the digital landscape evolves, it's vital to not only work 'in' your business but also 'on' it.";
	const heroImage = data.heroImg.childImageSharp.fluid;
	const daniel = data.daniel.childImageSharp.gatsbyImageData;
	const andrew = data.andrew.childImageSharp.gatsbyImageData;
	const ogImg = `${siteUrl}${schemaImage}`;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Industries",
				item: {
					url: `${siteUrl}/industries`,
					id: `${siteUrl}/industries`,
				},
			},
			{
				"@type": "ListItem",
				position: 3,
				name: "Web Designers & Digital Marketers",
				item: {
					url: `${siteUrl}/industries/web-designers-and-digital-marketers`,
					id: `${siteUrl}/industries/web-designers-and-digital-marketers`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={title}
				description={description}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/industries/web-designers-and-digital-marketers`,
					title: `${title}`,
					description: `${description}`,
					images: [
						{
							url: `${ogImg}`,
							width: 1920,
							height: 1080,
							alt: "Business & Executive Coach for Web Designers & Digital Marketers in London",
						},
					],
				}}
			/>
			<HeroEnd
				isDarken={true}
				backgroundImage={heroImage}
				title="Business & Executive Coach for Web Designers & Digital Marketers"
				description1=""
				description2=""
			/>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<p>
								Web designers and digital marketers, experts in crafting
								compelling online experiences, often encounter hurdles when
								trying to grow or scale their businesses profitably. As the
								digital landscape evolves, it's vital to not only work 'in' your
								business but also 'on' it.
							</p>
							<p>
								A business and executive coach can be your ally in this journey,
								offering the guidance needed to refine your business model and
								implement effective strategies. They help focus your efforts on
								what truly drives profitability and growth, ensuring you not
								only meet the current demands of the market but also anticipate
								future trends.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								See how business and executive coaching can benefit your agency
							</h3>
							<p>
								As a web designer or digital marketer, you might find it tough
								to grow or scale your business profitably amidst the fast-paced
								demands of the digital landscape. In our coaching sessions,
								we'll dive into strategies such as targeted niche marketing,
								innovating your service offerings, and optimising your pricing
								models to enhance profitability.{" "}
							</p>
							<p>
								With nearly four decades of business acumen, including over
								seven years specialising in coaching, I bring a depth of
								expertise in helping professionals like you overcome the hurdles
								unique to the digital marketing and design fields. Web designers
								and digital marketers face the continual challenge of adapting
								to rapidly changing technologies and client needs while striving
								to grow their business.
							</p>
							<p>
								Together, we will develop a tailored approach that not only
								tackles your immediate hurdles but also lays a robust groundwork
								for your business's profitable expansion and long-term success.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-right mr-3 mb-3 ml-lg-3 service-page-body-image"
								src="../../images/diagram.jpg"
							/>
							<h3 className="text-primary pb-3">
								Take your business to the next level with expert coaching
							</h3>
							<p>
								You're a web designer or digital marketer with the skills to
								create digital experiences and drive online campaigns, but when
								it comes to scaling your own business profitably, where do you
								turn? A business coach can be indispensable, offering not just
								insights into effective market strategies but also guidance on
								how to enhance your business model.
							</p>

							<p>Envision yourself:</p>
							<ul className="pl-4">
								<li>
									Mastering niche marketing to attract and retain the right
									clients.
								</li>
								<li>
									Innovating by adding new products and services that complement
									your existing offerings.
								</li>
								<li>
									Adjusting your pricing strategies to reflect the value you
									deliver.
								</li>
								<li>
									Growing your team effectively to support expanded operations.
								</li>
							</ul>
							<p>
								The impact of these strategies on your business, your clients,
								and your personal satisfaction can be transformative. But what
								barriers are holding you back from realising this? Partnering
								with a business coach who understands the unique challenges of
								web designers and digital marketers can help you identify these
								obstacles, navigate through them, and set a clear course for
								achieving your business goals.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-primary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">So, what’s been holding you back?</h2>
							<p>
								Are you a web designer or digital marketer feeling overwhelmed
								by project demands and deadlines, leaving not enough time for
								strategic business growth? If you're struggling to expand
								services, explore new markets, or adjust pricing strategies for
								better profitability, you're not alone.
							</p>
							<p>
								In the competitive, rapidly evolving field of digital marketing
								and web design, it’s easy to feel isolated as you navigate these
								complex challenges. Here’s how a dedicated Business & Executive
								Coach can help:
							</p>
							<ul className="pl-4">
								<li>
									Strategic Expansion: Guidance on effectively expanding your
									services and exploring new markets.
								</li>
								<li>
									Pricing Strategies: Assistance in evaluating and adjusting
									your pricing to enhance profitability.
								</li>
								<li>
									Product Innovation: Support in refining product offerings or
									introducing new ones to stay competitive.
								</li>
								<li>
									Team Building: Strategies to recruit, build, and motivate a
									team that aligns with your business and personal goals.
								</li>
								<li>
									Professional Guidance: Expert advice to elevate your service
									offerings and overall business performance.
								</li>
							</ul>
							<p>
								Now is the perfect time to tackle these issues with the support
								of an experienced, impartial, and confidential business coach.
							</p>
							<p>
								Together, we can transform these challenges into stepping stones
								for your professional advancement and business success. Let's
								collaborate to map out a strategy that ensures your business
								thrives for the long-term.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								size="lg"
								as={Link}
								to="/contact-us"
								className="cta-btn cta-btn-gtm"
							>
								Book a discovery meeting
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								How can coaching turn your business around?
							</h2>
							<p>
								As a business and executive coach specialising in supporting web
								designers and digital marketers, I recognise the unique
								challenges you face in growing your business profitably. While
								you excel in creating and implementing digital strategies, the
								real growth often comes from strategic business development.
								With me as your business coach, you'll receive customised
								coaching sessions designed specifically for you. Our focus will
								cover several critical areas:
							</p>

							<ul className="pl-4">
								<li>
									Personal and Professional Growth for Digital Professionals:
									Enhancing your skills and business acumen to stay ahead in the
									fast-paced digital world.
								</li>
								<li>
									Leadership and Team Development: Building and nurturing a team
									that's as dynamic and innovative as the digital landscapes you
									navigate.
								</li>
								<li>
									Strategic Business Planning: Tailoring strategies to your
									unique market position and business goals.
								</li>
								<li>
									Product and Service Innovation: Aligning your offerings with
									the evolving needs of your clients.
								</li>
							</ul>
							<p>
								My coaching approach provides bespoke guidance tailored to your
								specific needs, offering a confidential space to explore
								opportunities and overcome obstacles proactively. Together, we
								will tackle essential topics such as effective niche marketing,
								innovative service development, and strategic pricing
								adjustments, ensuring that your business not only grows but
								becomes a leading force in the digital marketing and web design
								industry.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-left mr-3 mb-3 mb-lg-1 service-page-body-image"
								src="../../images/12.jpg"
							/>
							<h2 className="text-primary pb-3">Asking the right questions</h2>

							<p>
								As an executive coach, my job centers around asking the right
								questions and carefully listening to what you say, tapping into
								the true sentiment behind your words and your thought process.
							</p>
							<p>
								I learned how to REALLY listen to my clients in a
								non-judgemental way during my 5 years of counselling and
								psychotherapy training.
							</p>
							<p>
								Executive coaching is all about listening properly without
								judging, I will uncover and support you with any false
								assumptions, fears and life-limiting beliefs. These are the
								things that may be holding you back, like a lack of confidence,
								imposter syndrome etc.
							</p>
							<p>
								Executive coaching like this can help you manage these thoughts
								and feelings, so they no longer impact your personal or
								professional life, while working alongside you to gain focus and
								make a significant impact on your life and business.
							</p>
							<p>
								Empowering you to think about the reasons ‘why you can’, rather
								than ‘why you can’t.’
							</p>
							<p>
								Let’s decide what you want, make a plan and use coaching to make
								it happen!
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			<section className="bg-tertiary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">About me as a business coach</h2>
							<p>
								With decades of business management experience and a solid
								grounding in both my MBA and counselling, I understand the
								unique hurdles you face as web designers and digital marketers.
								It's not just about creating compelling designs or campaigns;
								it's about making sure your business thrives and grows
								profitably.
							</p>
							<p>
								In our coaching sessions, we'll carve out a confidential space
								where you can openly discuss everything from deciding your
								vision and a plan to make it happen, to tweaking your pricing
								strategy, to expanding your service offerings. We'll explore
								targeted niche marketing and look at ways to innovate your
								current products.
							</p>
							<p>
								Together, we’ll focus on making strategic changes that can
								significantly boost your profitability. Let’s start working on
								your business, not just in it, setting you up for sustained
								growth and success in the digital realm. Ready to take your
								business to the next level? Let’s chat.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Reviews from my business and executive coaching clients
							</h2>
						</Col>
					</Row>

					<HorizontalTestimonial
						name="Mike Sherwood. FreshOnline Ltd"
						review="I have been working with Jason Cornes for the last 5 months and have seen amazing results. Jason has been able to understand my business and identify opportunities to improve what we already do and find new ways to grow and expand. Since working with Jason we have employed another full time staff member, added new services and increased profitability on our existing work. I feel so much more in control and confident about the future. I have no hesitation recommending Jason and I look forward to continuing to use his business coaching services long term."
						linkedIn="https://www.linkedin.com/in/mikesherwood147"
						profilePic={MikeS}
					/>

					<HorizontalTestimonial
						name="Robbie Mould – RJM Digital"
						review="Jason, Having worked with you the past few months on my business it is clear how your business coaching and mentoring is having a positive impact on how I’m able to focus and grow my company. With your coaching and mentoring I’ve been able to formulate a plan on where I want RJM Digital to be over the next 1 – 5 years. You have helped me understand all the crucial elements of business, identify my company’s strengths and weaknesses, and helped me take ownership of each stage of the journey. If there are any other ambitious business owners who want clarity, accountability, and expertise in working out how to grow and expand their business, I would highly recommend Jason Cornes."
						showLinkedIn="d-none"
						profilePic={robbie}
					/>
				</Container>
			</section>
			{/* <section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								Break the cycle with executive coaching in London
							</h3>
							<p>
								In this 24/7 world, leaders, senior managers, owners and
								executives are so busy that they don’t have the time to devote
								to their own development or to the development of the people who
								work for them. In other words, they’re caught in a vicious cycle
								of always “being busy.”
							</p>
							<p>
								In the same way, some struggle and worry about not being able to
								think clearly. You may have an idea, but you can’t seem to get a
								clear picture on what to do next, or perhaps you need to make
								decisions, but you don’t feel confident that the decision you
								make will be the right one.
							</p>
							<p>
								This is precisely why you need executive coaching services. As
								an executive coach in London, I can help you break the cycle of
								always being busy while giving you the confidence to make
								decisions that positively impact your life.
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			{/* <section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Ready to work towards that positive future?
							</h2>
							<p>
								Unfortunately, though, none of us have a crystal ball. I can’t
								tell you what life will be like if you do, take that job, wake
								up filled with confidence, or grow your business tenfold - but
								what I can tell you is...
							</p>
							<p>If you do nothing, nothing will change.</p>
							<p>
								On the other hand, it’s amazing what we can achieve together
								when we use coaching to focus on what you really want.
							</p>
							<p>
								So, with nothing to lose - why not book a introductory call with
								me?
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			<section className="bg-secondary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h3 className="pb-3">Let's schedule a call together</h3>
							<p>
								Web designers and digital marketers like you are great at diving
								into the details of each project, creating stunning visuals, and
								deploying cutting-edge campaigns. But I know it can be tough to
								step back and focus on growing your business, especially when
								you're caught up in the day-to-day.
							</p>
							<p>
								Why don’t we hop on a discovery call? It’ll be a relaxed chat
								where we can get to know each other and explore how personalised
								business and executive coaching could really help you not just
								meet but exceed your business goals. Let’s take this chance to
								focus on expanding and enhancing your business together.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								className="cta-btn cta-btn-gtm"
								size="lg"
								as={Link}
								to="/contact-us"
							>
								Get in Touch
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <CtaBanner
        headline="Executive Coaching"
        btnTxt="Book a call"
        btnLink="/contact-us"
        bgColor="light"
      /> */}
		</Layout>
	);
};
export default WebDesignersAndDigitalMarketers;
